import React from "react"
import { FaTwitter, FaLinkedin, FaFacebook, FaEnvelope } from "react-icons/fa"
import { ShareBlockStandard, ShareButtonIconOnly } from "react-custom-share"

const CustomShareBlock = ({ url, title, siteName }) => {
    const shareBlockProps = {
        url,
        button: ShareButtonIconOnly,
        buttons: [
            { network: "Twitter", icon: FaTwitter },
            { network: "Facebook", icon: FaFacebook },
            { network: "Linkedin", icon: FaLinkedin },
            { network: "Email", icon: FaEnvelope }
        ],
        text: title,
        longtext: siteName
    };
    return (
        <div className="mt-4">
            <ShareBlockStandard {...shareBlockProps} />
            <p className="text-center"><i>Si te ha gustado... ¡Compártelo!</i></p>
        </div>
    )
};

export default CustomShareBlock

