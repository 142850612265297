import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import CustomShareBlock from "../components/CustomShareBlock"
import { renderTags, dateToString } from '../utils'

const BlogPost = ({ data, pageContext }) => {
  const { title: siteName, url: siteUrl } = data.site.siteMetadata
  const { html, frontmatter: { tags: postTags, title, description, date, featuredImage } } = data.markdownRemark
  const url = `${siteUrl}${pageContext.slug}`
  const Image = featuredImage && featuredImage.childImageSharp ? featuredImage.childImageSharp.fluid : null
  return (
    <Layout title={title} description={description} image={Image && Image.src}>
      <article className="main-content post-main mt-3 p-3">
        <header>
          <small className="d-block text-muted mb-1"><i>{dateToString(date)}</i></small>
          <h1 className="heading">{title}</h1>
          <div className="d-block">
            {renderTags(postTags)}
          </div>
          {Image ? (
            <Img
              fluid={Image}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={title}
              className="my-3 featured-image"
            />
          ) : (
              ''
            )}
        </header>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: html }} />
        <CustomShareBlock title={title} siteName={siteName} url={url} />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        url
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date
        tags
        featuredImage {
          relativePath
          childImageSharp {
            fluid(maxWidth: 950) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default BlogPost
